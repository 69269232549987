<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-column align-center'>
          <span class='d-flex flex-row align-center'>
            <v-btn text class="mx-2" @click="()=>{this.$router.go(-1)}">
              <v-icon dark>mdi-arrow-left</v-icon>
            </v-btn>
            <h1>
              Hire Purchase Plan # {{ data.id }}
              <v-chip class="mr-4" :color="utils.getHPStatusColor()">{{ utils.parseHPStatus(data.status) }} </v-chip>
            </h1>
            <confirmedActionButton
              class="ml-3"
              color="info" 
              small
              buttonText="Close Account"
              requireUsername
              requirePassword
              @cb="closeHPA"
              fabIcon="mdi-close"
              :dialogText="'Are you sure you wish to proceed?'"
              :disabled="data.status == -1 || data.status > 2 || data.balance !== 0"
            />
            <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
            ></v-progress-circular>
          </span>
          <v-card v-if="data && data.Order && data.Order.Transactions" outlined style="width: 100%;" class="mt-3">
            <v-card-text class="d-flex flex-row">
              <h1 class="mr-4">
                Balance: {{ utils.formatCurrency(data.balance||0) }}
              </h1>
              <h1 class="mr-4">
                Payment Status: {{ data.paymentStatus }} <span v-if="this.ppcLabels[parseInt(data.paymentStatus)-2]">({{ this.ppcLabels[parseInt(data.paymentStatus)-2] }})</span>
              </h1>
              <h1 v-if="data.Order && data.Order.Transactions && data.Order.Transactions.length > 0" class="mr-4">
                DSLP: <span v-if="data.status >= 0 && data.status < 3 && data.balance !== 0">{{utils.daysBetween(new Date(data.Order.Transactions[data.Order.Transactions.length-1].createdAt), new Date())}}</span>
                <span v-else>N/A</span>
              </h1>
            </v-card-text>
          </v-card>
          <div v-if="getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_HP_VIEW')" style="width: 100%;">
            <dynamicButtonContainer class="mt-3" :containerId="`${getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_HP_VIEW')}`" :data="{status: data.status, id1: $route.params.id}" style="width: 100%;"/>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-column" cols="8">
          <div>
            <span class="d-flex flex-row align-center">
              <h2>Terms</h2>
              <confirmedActionButton
                class="ml-3"
                color="success" 
                small
                buttonText="Seal"
                @cb="sealHPA"
                fabIcon="mdi-close"
                :dialogText="'Are you sure you wish to proceed?'"
                :disabled="modifyDisabledCriteria || !dataArrays.term || !dataArrays.amount || !dataArrays.downpayment || !dataArrays.interestRate || !dataArrays.installment"
              />
            </span>
            <v-card>
              <v-card-text class="d-flex flex-row">
                <v-text-field class="mr-2" :disabled="modifyDisabledCriteria" @change="updateTerm" :loading="dataLoaders.term" v-model="dataArrays.term" outlined dense type="number" min=0 label="Term Duration (Months)"/>
                <v-text-field class="mr-2" disabled @change="updateAmount"  :loading="dataLoaders.amount" v-model="dataArrays.amount" outlined dense type="number" min=0 label="Amount ($)"/>
                <v-text-field class="mr-2" :disabled="modifyDisabledCriteria" @change="updateDownpayment" :loading="dataLoaders.downpayment" v-model="dataArrays.downpayment" outlined dense type="number" min=0 label="Downpayment ($)"/>
                <v-text-field class="mr-2" :disabled="modifyDisabledCriteria" @change="updateInterestRate" :loading="dataLoaders.interestRate" v-model="dataArrays.interestRate" outlined dense type="number" min=0 label="Interest Rate (%)"/>
                <v-text-field class="mr-2" :disabled="modifyDisabledCriteria" @change="updateInstallment" :loading="dataLoaders.installment" v-model="dataArrays.installment" outlined dense type="number" min=0 label="Installment Amount ($)"/>
              </v-card-text>
            </v-card>
          </div>
          <div v-if="data.Order">
            <h2 class="mt-3">Order</h2>
            <div class="d-flex flex-column">
              <span class="d-flex flex-row align-center">
                <v-card class="my-3" outlined style="width: 100%;">
                  <v-card-text class="d-flex flex-row">
                    <h4 class="mr-4 d-flex flex-column">
                      <div>Order ID:</div>
                      <router-link :to="'/orders/view/'+data.orderId" v-if="data.Order">{{ data.orderId }}</router-link>
                    </h4>
                    <h4 class="mr-4 d-flex flex-column">
                      <div>Products:</div>
                      <span class="d-flex flex-column" v-for="(item,index) in data.Order.OrderLineItems" :key="index">
                        <b><router-link :to="'/products/view/'+item.productId" class="mr-2">PL-{{ item.productId }}</router-link>{{ item.quantity }}x {{ item.productName }} (SKU:{{ item.sku }})</b>
                      </span>
                    </h4>
                  </v-card-text>
                </v-card>
              </span>
            </div>            
            <h2 class="mt-3">Customer</h2>
            <div class="d-flex flex-column">
              <span class="d-flex flex-row align-center">
                <v-card class="my-3" outlined style="width: 100%;">
                  <v-card-text class="d-flex flex-column">
                    <div class="d-flex flex-row">
                      <h4 class="mr-4 d-flex flex-column">
                        <div>Customer One ID:</div>
                        <router-link :to="'/customers/view/'+data.Order.customerId" v-if="data.Order">{{ data.Order.customerId }}</router-link>
                      </h4>
                      <h4 class="mr-4 d-flex flex-column">
                        <div>Name:</div> 
                        <div>{{ data.Order.Customer.name }}</div>
                      </h4>
                      <h4 class="mr-4 d-flex flex-column">
                        <div>Phone:</div> 

                      </h4>
                      <h4 class="mr-4 d-flex flex-column">
                        <div>Phone:</div> 
                        <div>{{ data.Order.Customer.phone }}</div>
                      </h4>
                      <h4 class="mr-4 d-flex flex-column">
                        <div>Email:</div> 
                        <div>{{ data.Order.Customer.email }}</div>
                      </h4>
                      <h4 class="mr-4 d-flex flex-column">
                        <div>Primary Address: </div> 
                        <div v-if="data.Order && data.Order.Customer && data.Order.Customer.addresses && data.Order.Customer.addresses.length>0">{{ data.Order.Customer.addresses[0].line1 }} {{ data.Order.Customer.addresses[0].line2 }}, {{ data.Order.Customer.addresses[0].city }}, {{ data.Order.Customer.addresses[0].country }}</div>
                        <i v-else>You need to add a customer address.</i>
                      </h4>
                    </div>
                    <div>
                      <span class="d-flex flex-row align-center">
                        <h3>Sponsors</h3>
                        <v-btn fab class="success ml-2 my-2" x-small @click="openAddEditSponsorsDialog(true, null)"><v-icon>mdi-plus</v-icon></v-btn>
                      </span>
                      <v-data-table
                        v-if="data.sponsors"
                        :headers="sponsorsHeader"
                        :items="data.sponsors"
                        :loading="loader" 
                        :items-per-page="-1"
                        class="elevation-1"
                        >
                        <template v-slot:item.createdBy="{ item }">
                          <span v-if="item.createdBy">{{lookupUsername(item.createdBy)}}</span>
                          <span v-else>-</span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-btn @click="openAddEditSponsorsDialog(false,item)" class="warning" fab x-small> <v-icon>mdi-pencil</v-icon> </v-btn>
                        </template>
                        <template v-slot:item.createdAt="{ item }">
                          <span>{{utils.formatDate(item.createdAt, 'withTime')}}</span>
                        </template>
                      </v-data-table>
                      <span class="d-flex flex-row align-center">
                        <h3>Employment History</h3>
                        <v-btn fab class="success ml-2" x-small @click="openAddEditEmployersDialog(true, null)"><v-icon>mdi-plus</v-icon></v-btn>
                      </span>
                      <v-data-table
                        v-if="data.employers"
                        :headers="employersHeader"
                        :items="data.employers"
                        :loading="loader" 
                        :items-per-page="-1"
                        class="elevation-1"
                        >
                        <template v-slot:item.createdBy="{ item }">
                          <span v-if="item.createdBy">{{lookupUsername(item.createdBy)}}</span>
                          <span v-else>-</span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-btn @click="openAddEditEmployersDialog(false,item)" class="warning" fab x-small> <v-icon>mdi-pencil</v-icon> </v-btn>
                        </template>
                        <template v-slot:item.createdAt="{ item }">
                          <span>{{utils.formatDate(item.createdAt, 'withTime')}}</span>
                        </template>
                        <template v-slot:item.company="{ item }">
                          <span class="d-flex flex-column">
                            <span>Company Name: {{item.name}} </span>
                            <span>Phone: {{item.phone}} </span>
                            <span>Email: {{item.email}} </span>
                            <span>Address: {{item.address}}</span>
                          </span>
                        </template>
                        <template v-slot:item.contact="{ item }">
                          <span class="d-flex flex-column">
                            <span>Contact Name: {{item.contactName}} </span>
                            <span>Phone: {{item.contactPhone}} </span>
                            <span>Email: {{item.contactEmail}} </span>
                            <span>Occupation: {{item.contactOccupation}}</span>
                          </span>
                        </template>
                      </v-data-table>
                    </div>
                  </v-card-text>
                </v-card>
              </span>
            </div>
          </div>
          <div>
            <span class="d-flex flex-row align-center">
              <h2>HP Charges</h2>
              <v-btn fab class="success ml-2" x-small @click="openAddEditPenaltyDialog(true, null)"><v-icon>mdi-plus</v-icon></v-btn>
            </span>
            <v-data-table
              v-if="data.penalties"
              :headers="penaltiesHeader"
              :items="data.penalties"
              :loading="loader"
              :items-per-page="-1"
              class="elevation-1"
              >
              <template v-slot:item.status="{ item }">
                <span v-if="item.deletedAt">Deleted at {{ utils.formatDate(item.deletedAt, 'withTime') }} by {{ lookupUsername(item.deletedBy) }}</span>
                <span v-else>Active</span>
              </template>
              <template v-slot:item.createdBy="{ item }">
                <span v-if="item.createdBy || item.createdBy === 0">{{lookupUsername(item.createdBy)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:item.amount="{ item }">
                <span>{{utils.formatCurrency(item.amount)}}</span>
              </template>
              <template v-slot:item.notes="{ item }">
                <span v-if="item.balanceToDate">{{ utils.formatCurrency(item.balanceToDate) }}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <confirmedActionButton
                  class="mr-3"
                  color="error" 
                  xsmall
                  fab
                  @cb="deletePenalty(item.index)"
                  fabIcon="mdi-close"
                  :dialogText="'Are you sure you wish to proceed?'"
                  :disabled="!!item.deletedAt || data.status < 0 || data.status > 2 || data.balance === 0"
                />
                <!-- <v-btn :disabled="!!item.deletedAt || data.status < 0 || data.status > 2 || data.balance === 0" @click="openAddEditPenaltyDialog(false,item.index)" class="mr-2 warning" fab x-small> <v-icon>mdi-pencil</v-icon> </v-btn> -->
                <!-- <v-btn @click="openPenaltyHistoryDialog(item.index,item.previousVersions)" class="info" fab x-small> <v-icon>mdi-history</v-icon> </v-btn> -->
              </template>
              <template v-slot:item.createdAt="{ item }">
                <span>{{utils.formatDate(item.createdAt, 'withTime')}}</span>
              </template>
            </v-data-table>
          </div>
          <div class="mt-3">
            <span class="d-flex flex-row align-center">
              <h2>Discounts</h2>
              <v-btn fab class="success ml-2" :disabled="data.balance === 0 || data.status === -1 || data.status > 2" x-small @click="openAddDiscountDialog(true, null)"><v-icon>mdi-plus</v-icon></v-btn>
            </span>
            <v-data-table
              v-if="data.discounts"
              :headers="discountsHeader"
              :items="data.discounts"
              :loading="loader"
              :items-per-page="-1"
              class="elevation-1"
              >
              <template v-slot:item.status="{ item }">
                <span v-if="item.deletedAt">Voided at {{ utils.formatDate(item.deletedAt, 'withTime') }} by {{ lookupUsername(item.deletedBy) }}</span>
                <span v-else>Active</span>
              </template>
              <template v-slot:item.createdBy="{ item }">
                <span v-if="item.createdBy || item.createdBy === 0">{{lookupUsername(item.createdBy)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:item.amount="{ item }">
                <span>{{utils.formatCurrency(item.amount)}}</span>
              </template>
              <template v-slot:item.notes="{ item }">
                <span v-if="item.balanceToDate">{{ utils.formatCurrency(item.balanceToDate) }}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <confirmedActionButton
                  class="mr-3"
                  color="error" 
                  xsmall
                  fab
                  @cb="deleteDiscount(item.index)"
                  fabIcon="mdi-close"
                  :dialogText="'Are you sure you wish to proceed?'"
                  :disabled="!!item.deletedAt || data.status < 0 || data.status > 2 || data.balance === 0"
                />
                <!-- <v-btn :disabled="!!item.deletedAt || data.status < 0 || data.status > 2 || data.balance === 0" @click="openAddEditPenaltyDialog(false,item.index)" class="mr-2 warning" fab x-small> <v-icon>mdi-pencil</v-icon> </v-btn> -->
                <!-- <v-btn @click="openPenaltyHistoryDialog(item.index,item.previousVersions)" class="info" fab x-small> <v-icon>mdi-history</v-icon> </v-btn> -->
              </template>
              <template v-slot:item.createdAt="{ item }">
                <span>{{utils.formatDate(item.createdAt, 'withTime')}}</span>
              </template>
            </v-data-table>
          </div>     
        </v-col>
        <v-col class="d-flex flex-column" cols="4">
          <div>
            <span class="d-flex flex-row align-center">
              <h2>Payments</h2>
              <v-btn fab class="success ml-2" x-small :disabled="data.status < 1 || data.status > 2 || data.balance === 0" @click="openAddPaymentDialog"><v-icon>mdi-plus</v-icon></v-btn>
            </span>
            <v-data-table
              v-if="data.Order && data.Order.Transactions"
              :headers="paymentsHeader"
              :items="data.Order.Transactions"
              :loading="loader"
              :items-per-page="-1"
              class="elevation-1"
              >
              <template v-slot:item.receivedBy="{ item }">
                <span v-if="item.receivedBy">{{lookupUsername(item.receivedBy)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:item.amount="{ item }">
                <span>{{utils.formatCurrency(item.amount)}}</span>
              </template>
              <template v-slot:item.createdAt="{ item }">
                <span>{{utils.formatDate(item.createdAt, 'withTime')}}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <!-- <v-btn @click="openViewPaymentDialog(item)" class="info" fab x-small> <v-icon>mdi-chevron-right</v-icon> </v-btn> -->
                <dynamicButtonContainer :containerId="`${getGlobalValue('VEC_HP_PAYMENTS_DBC')}`" :data="{status: data.status, id1: item.id}" style="width: 100%;"/>
              </template>              
            </v-data-table>
          </div> 
          <div>
            <span class="d-flex flex-row align-center">
              <h2>Notes</h2>
              <v-btn fab class="success ml-2" x-small @click="openAddNoteDialog"><v-icon>mdi-plus</v-icon></v-btn>
            </span>
          </div>
          <p v-if="!data.notes || data.notes.length < 1">Add a note.</p>
          <v-card v-else outlined>
            <v-card-text>
              <div class="d-flex flex-column" style="margin-bottom: 10px; border-radius: 7px; background-color: rgba(0,0,0,0.25); padding: 10px;" v-for="(item,index) in data.notes" :key="'n'+index">
                <b style="color: #2196f3">{{ lookupUsername(item.createdBy) }} at {{ utils.formatDate(item.createdAt, 'withTime') }}</b>
                <span style="white-space: pre; font-size: 12px; line-height: 12px;">{{ item.value }}</span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="termHistoryDialog.isOpen">
      <v-card>
        <v-card-title>
          History of: {{ termHistoryDialog.type }}
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="termHistoryHeaders"
            :items="termHistoryDialog.data"
            :loading="termHistoryDialog.isLoading"
            :items-per-page="-1"
            class="elevation-1"
            >
            <template v-slot:item.createdBy="{ item }">
              <span v-if="item.createdBy || item.createdBy===0">{{lookupUsername(item.createdBy)}}</span>
              <span v-else>-</span>
            </template>
            <template v-slot:item.createdAt="{ item }">
              <span>{{utils.formatDate(item.createdAt, 'withTime')}}</span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" @click="closeTermHistoryDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addNoteDialog.isOpen" persistant width="600">
      <v-card>
        <v-card-title>Add Note</v-card-title>
        <v-card-text>
          <v-textarea dense name="Note" v-model="addNoteDialog.data" outlined ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="addNoteDialog.isLoading" color="primary" @click="addNote">Add</v-btn>
          <v-btn color="success" @click="closeAddNoteDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addPaymentDialog.isOpen" scrollable max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Add Payment
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="addPaymentDialog.selectedType" row>
            <v-radio
              v-for="n in paymentTypes.filter(x=>!x.hidden)"
              :key="n.id"
              :label="n.name"
              :value="n.id"
            ></v-radio>
          </v-radio-group>
          <hr>
          <span class="mt-3 d-flex flex-row align-center">
            <h1>Amount</h1>
          </span>
          <v-text-field style="margin-top: 10px;" v-model="addPaymentDialog.tenderedAmount" :disabled="this.lookupPaymentType(addPaymentDialog.selectedType)=='Credit Note'" type="number" label="Amount Tendered" outlined></v-text-field>
          <v-text-field v-if="this.lookupPaymentType(addPaymentDialog.selectedType)=='Cheque'" style="margin-top: 10px;" dense v-model="addPaymentDialog.chequeNumber" label="Cheque Number" outlined></v-text-field>
          <span v-if="this.lookupPaymentType(addPaymentDialog.selectedType)=='Credit Note'">
            <v-radio-group v-model="addPaymentDialog.creditNote" @change="updateSelectedCreditNote">
              <v-radio
                v-for="(cn, index) in addPaymentDialog.creditNoteAvailable"
                :key="index"
                :label="cn.name"
                :value="cn.id"
              ></v-radio>
            </v-radio-group>
            <p v-if="addPaymentDialog.cnMessage">{{addPaymentDialog.cnMessage}}</p>
          </span>
          <v-text-field outlined label="Notes" v-model="addPaymentDialog.notes"/>
        </v-card-text> 
        <v-card-actions>
          <v-btn small text color="error" @click="closeAddPaymentDialog()">
            Cancel
          </v-btn>
          <v-btn :disabled="addPaymentDialogDisabledCriteria" :loading="addPaymentDialog.isLoading" small color="success" @click="addPayment">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewPaymentDialog.isOpen" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          View Payment
        </v-card-title>
        <v-card-text>
          <p>Date Received: {{utils.formatDate(viewPaymentDialog.paymentToDisplay.createdAt)}} {{utils.formatTime(viewPaymentDialog.paymentToDisplay.createdAt)}}</p>
          <p>Received By: {{lookupUsername(viewPaymentDialog.paymentToDisplay.receivedBy)}}</p>
          <p>Amount: {{utils.formatCurrency(viewPaymentDialog.paymentToDisplay.amount)}}</p>
          <p>Amount Tendered: {{utils.formatCurrency(viewPaymentDialog.paymentToDisplay.metadata?.tenderedAmount)}}</p>
          <p>Change Issued: {{utils.formatCurrency(parseFloat(viewPaymentDialog.paymentToDisplay.metadata?.tenderedAmount)-parseFloat(viewPaymentDialog.paymentToDisplay.amount))}}</p>
          <p>Payment Type: {{lookupPaymentType(viewPaymentDialog.paymentToDisplay.paymentType)}}</p>
          <p v-if="lookupPaymentType(viewPaymentDialog.paymentToDisplay.paymentType)=='Cheque'">Cheque Number: {{viewPaymentDialog.paymentToDisplay.metadata.chequeNumber}}</p>
          <span v-if="lookupPaymentType(viewPaymentDialog.paymentToDisplay.paymentType)=='Credit Note'">
            <span>Credit Notes Used: </span>
            <span v-if="Array.isArray(viewPaymentDialog.paymentToDisplay.metadata.creditNotes)">
              <span v-for="cn in viewPaymentDialog.paymentToDisplay.metadata.creditNotes" :key="cn.id">
                {{cn}}
              </span>
            </span>
            <span v-else>
              <span>{{viewPaymentDialog.paymentToDisplay.metadata.creditNotes}}</span>
            </span>

          </span>
          <p v-if="viewPaymentDialog.paymentToDisplay.cnMessage">{{viewPaymentDialog.paymentToDisplay.cnMessage}}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn small text color="error" @click="closeViewPaymentDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="addEditSponsorsDialog.isOpen" persistant width="600">
      <v-card>
        <v-card-title>
          <span v-if="addEditSponsorsDialog.addMode">Add</span>
          <span v-else>Edit</span>
          Sponsor
        </v-card-title>
        <v-card-text v-if="addEditSponsorsDialog.data">
          <v-text-field v-model="addEditSponsorsDialog.data.name" outlined dense label="Name"/>
          <v-text-field v-model="addEditSponsorsDialog.data.phone" outlined dense label="Phone"/>
          <v-text-field v-model="addEditSponsorsDialog.data.address" outlined dense label="Address"/>
          <v-text-field v-model="addEditSponsorsDialog.data.email" outlined dense label="Email"/>
          <v-text-field v-model="addEditSponsorsDialog.data.relationship" outlined dense label="Relationship"/>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="addEditSponsorsDialog.addMode" :loading="this.dataLoaders.sponsors" color="primary" @click="addSponsors">Add</v-btn>
          <span v-else>
            <v-btn class="mr-2" color="primary" :loading="this.dataLoaders.sponsors" @click="updateSponsors">Update</v-btn>
            <confirmedActionButton 
              class="mr-2"
              color="error" 
              button-text="Delete"
              @cb="deleteSponsors" 
              :loading="this.dataLoaders.sponsors"
              :dialogText="'Are you sure you wish to proceed?'"
            />
          </span>
          <v-btn color="success" @click="closeAddEditSponsorsDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addEditEmployersDialog.isOpen" persistant width="600">
      <v-card>
        <v-card-title>
          <span v-if="addEditEmployersDialog.addMode">Add</span>
          <span v-else>Edit</span>
          Employer
        </v-card-title>
        <v-card-text v-if="addEditEmployersDialog.data">
          <v-text-field v-model="addEditEmployersDialog.data.occupation" outlined dense label="Occupation"/>
          <v-text-field v-model="addEditEmployersDialog.data.salary" outlined type="number" min="0" dense label="Salary"/>
          <v-text-field v-model="addEditEmployersDialog.data.name" outlined dense label="Name"/>
          <v-text-field v-model="addEditEmployersDialog.data.phone" outlined dense label="Phone"/>
          <v-text-field v-model="addEditEmployersDialog.data.email" outlined dense label="Email"/>
          <v-text-field v-model="addEditEmployersDialog.data.address" outlined dense label="Address"/>
          <v-text-field v-model="addEditEmployersDialog.data.contactName" outlined dense label="Contact Name"/>
          <v-text-field v-model="addEditEmployersDialog.data.contactPhone" outlined dense label="Contact Phone"/>
          <v-text-field v-model="addEditEmployersDialog.data.contactEmail" outlined dense label="Contact Email"/>
          <v-text-field v-model="addEditEmployersDialog.data.contactOccupation" outlined dense label="Contact Occupation"/>
          <v-text-field v-model="addEditEmployersDialog.data.dateStarted" outlined dense label="Date Started"/>
          <v-text-field v-model="addEditEmployersDialog.data.dateEnded" outlined dense label="Date Ended"/>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="addEditEmployersDialog.addMode" :loading="this.dataLoaders.employers" color="primary" @click="addEmployers">Add</v-btn>
          <span v-else>
            <v-btn class="mr-2" color="primary" :loading="this.dataLoaders.employers" @click="updateEmployers">Update</v-btn>
            <confirmedActionButton 
              class="mr-2"
              color="error" 
              button-text="Delete"
              @cb="deleteEmployers" 
              :loading="this.dataLoaders.employers"
              :dialogText="'Are you sure you wish to proceed?'"
            />
          </span>
          <v-btn color="success" @click="closeAddEditEmployersDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addEditPenaltyDialog.isOpen" persistant width="600">
      <v-card>
        <v-card-title>
          <span v-if="addEditPenaltyDialog.addMode">Add </span>
          <span v-else>Edit</span>
          <span class="ml-2">HP Charge</span>
        </v-card-title>
        <v-card-text v-if="addEditPenaltyDialog.data">
          <v-checkbox hide-details class="mb-2" dense label="Penalty" v-model="addEditPenaltyDialog.data.isPenalty"/>
          <v-text-field v-model="addEditPenaltyDialog.data.description" outlined dense label="Description"/>
          <v-text-field v-model="addEditPenaltyDialog.data.amount" :disabled="!(!(addEditPenaltyDialog.data.percent))" outlined dense type="number" min="0" label="Amount"/>
          <v-text-field v-model="addEditPenaltyDialog.data.percent" clearable @change="addEditPenaltyPercentValueChanged()" outlined dense type="number" min="1" label="Percentage"/>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="addEditPenaltyDialog.addMode" :loading="this.dataLoaders.penalty" color="primary" @click="addPenalty">Add</v-btn>
          <span v-else>
            <confirmedActionButton 
              class="mr-2"
              color="error" 
              button-text="Delete"
              @cb="deletePenalty(addEditPenaltyDialog.index)" 
              :loading="this.dataLoaders.penalty"
              :dialogText="'Are you sure you wish to proceed?'"
            />
          </span>
          <v-btn color="success" @click="closeAddEditPenaltyDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="penaltyHistoryDialog.isOpen" persistant>
      <v-card>
        <v-card-title>
          Edits of this Penalty
        </v-card-title>
        <v-card-text v-if="penaltyHistoryDialog.data">
          <v-data-table
            :headers="penaltiesHistoryHeader"
            :items="penaltyHistoryDialog.data"
            :items-per-page="-1"
            class="elevation-1"
            >
            <template v-slot:item.current="{ item }">
              <span v-if="item.currentValue">Current</span>
            </template>
            <template v-slot:item.status="{ item }">
              <span v-if="item.deletedAt">Voided at {{ utils.formatDate(item.deletedAt, 'withTime') }} by {{ lookupUsername(item.deletedBy) }}</span>
              <span v-else>Active</span>
            </template>
            <template v-slot:item.createdBy="{ item }">
              <span v-if="item.createdBy">{{lookupUsername(item.createdBy)}}</span>
              <span v-else>-</span>
            </template>
            <template v-slot:item.amount="{ item }">
              <span>{{utils.formatCurrency(item.amount)}}</span>
            </template>
            <template v-slot:item.createdAt="{ item }">
              <span>{{utils.formatDate(item.createdAt, 'withTime')}}</span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" @click="closePenaltyHistoryDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDiscountDialog.isOpen" persistant width="600">
      <v-card>
        <v-card-title>
          <span class="ml-2">Add Discount</span>
        </v-card-title>
        <v-card-text v-if="addDiscountDialog.data">
          <v-text-field v-model="addDiscountDialog.data.description" outlined dense label="Description"/>
          <v-text-field v-model="addDiscountDialog.data.amount" :disabled="!(!(addDiscountDialog.data.percent))" outlined dense type="number" min="0" label="Amount"/>
          <v-text-field v-model="addDiscountDialog.data.percent" clearable @change="addEditDiscountPercentValueChanged()" outlined dense type="number" min="1" label="Percentage"/>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="addDiscountDialog.addMode" :loading="this.dataLoaders.Discount" color="primary" @click="addDiscount">Add</v-btn>
          <span v-else>
            <confirmedActionButton 
              class="mr-2"
              color="error" 
              button-text="Delete"
              @cb="deleteDiscount(addDiscountDialog.index)" 
              :loading="this.dataLoaders.discount"
              :dialogText="'Are you sure you wish to proceed?'"
            />
          </span>
          <v-btn color="success" @click="closeAddDiscountDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="discountHistoryDialog.isOpen" persistant>
      <v-card>
        <v-card-title>
          Edits of this Discount
        </v-card-title>
        <v-card-text v-if="discountHistoryDialog.data">
          <v-data-table
            :headers="penaltiesHistoryHeader"
            :items="discountHistoryDialog.data"
            :items-per-page="-1"
            class="elevation-1"
            >
            <template v-slot:item.current="{ item }">
              <span v-if="item.currentValue">Current</span>
            </template>
            <template v-slot:item.status="{ item }">
              <span v-if="item.deletedAt">Deleted at {{ utils.formatDate(item.deletedAt, 'withTime') }} by {{ lookupUsername(item.deletedBy) }}</span>
              <span v-else>Active</span>
            </template>
            <template v-slot:item.createdBy="{ item }">
              <span v-if="item.createdBy">{{lookupUsername(item.createdBy)}}</span>
              <span v-else>-</span>
            </template>
            <template v-slot:item.amount="{ item }">
              <span>{{utils.formatCurrency(item.amount)}}</span>
            </template>
            <template v-slot:item.createdAt="{ item }">
              <span>{{utils.formatDate(item.createdAt, 'withTime')}}</span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" @click="closeDiscountHistoryDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  import utils  from "../../plugins/helpers"
  import confirmedActionButton from './../../components/confirmedActionButton.vue';
  import dynamicButtonContainer from './../../components/dynamicButtonContainer.vue';
  export default {
    components: {
      confirmedActionButton,
      dynamicButtonContainer
    },
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        ppcLabels: [],
        dataLoaders: {},
        dataArrays: {},
        snackObj: {
          state: false,
          color: '',
          text: ''
        },
        termHistoryDialog: {
          isOpen: false,
          isLoading: false,
          data: {},
        },
        addNoteDialog: {
          isOpen: false,
          isLoading: false,
          data: ""          
        },   
        addPaymentDialog: {
          isOpen: false,
          isLoading: false,
          data: {}          
        },     
        viewPaymentDialog: {
          isOpen: false,
          paymentToDisplay: {},
        },
        addEditSponsorsDialog: {
          isOpen: false,
          isLoading: false,
          addMode: true,
          data: {
            name: null,
            phone: null,
            email: null,
            relationship: null,
          }
        },
        addEditPenaltyDialog: {
          isOpen: false,
          isLoading: false,
          addMode: true,
          index: null,
          data: {
            description: null,
            isPenalty: null,
            amount: null,
            percent: null,
          }
        },
        addDiscountDialog: {
          isOpen: false,
          isLoading: false,
          addMode: true,
          index: null,
          data: {
            description: null,
            amount: null,
            percent: null,
          }
        },
        penaltyHistoryDialog: {
          isOpen: false,
          isLoading: false,
          data: []
        },
        discountHistoryDialog: {
          isOpen: false,
          isLoading: false,
          data: []
        },
        addEditEmployersDialog: {
          isOpen: false,
          isLoading: false,
          addMode: true,
          data: {
            occupation: null,
            salary: null,
            name: null,
            phone: null,
            email: null,
            address: null,
            contactName: null,
            contactPhone: null,
            contactEmail: null,
            contactOccupation: null,
            employmentStarted: null,
            employmentEnded: null,
          }
        },
        termHistoryHeaders: [
          { text: 'Value', value: 'value' },
          { text: 'Created By', value: 'createdBy' },
          { text: 'Created At', value: 'createdAt' },
        ],
        installmentHistoryHeaders: [
          { text: 'Created By', value: 'createdBy' },
          { text: 'Created At', value: 'createdAt' },
          { text: 'Value', value: 'value' },
        ],
        interestRateHistoryHeaders: [
          { text: 'Created By', value: 'createdBy' },
          { text: 'Created At', value: 'createdAt' },
          { text: 'Value', value: 'value' },
        ],
        amountHistoryHeaders: [
          { text: 'Created By', value: 'createdBy' },
          { text: 'Created At', value: 'createdAt' },
          { text: 'Value', value: 'value' },
        ],
        downpaymentHistoryHeaders: [
          { text: 'Created By', value: 'createdBy' },
          { text: 'Created At', value: 'createdAt' },
          { text: 'Value', value: 'value' },
        ],
        sponsorsHeader: [
          { text: 'Name', value: 'name' },
          { text: 'Phone', value: 'phone' },
          { text: 'Email', value: 'email' },
          { text: 'Address', value: 'address' },
          { text: 'Relationship', value: 'relationship' },
          { text: 'Created By', value: 'createdBy' },
          { text: 'Created At', value: 'createdAt' },
          { text: '', value: 'actions' },
        ],
        employersHeader: [
          { text: 'Employment Occupation', value: 'occupation' },
          { text: 'Salary', value: 'salary' },
          { text: 'Company', value: 'company' },
          { text: 'Contact', value: 'contact' },
          { text: 'Employment Started', value: 'dateStarted' },
          { text: 'Employment Ended', value: 'dateEnded' },
          { text: 'Created At', value: 'createdAt' },
          { text: '', value: 'actions' },
        ],
        penaltiesHeader: [
          { text: 'Status', value: 'status' },
          { text: 'Description', value: 'description' },
          { text: 'Percentage', value: 'percent' },
          { text: 'Amount', value: 'amount' },
          { text: 'BAP', value: 'notes' },
          { text: 'Created By', value: 'createdBy' },
          { text: 'Created At', value: 'createdAt' },
          { text: '', value: 'actions' },
        ],
        penaltiesHistoryHeader: [
          { text: '', value: 'current' },
          { text: 'Status', value: 'status' },
          { text: 'Created By', value: 'createdBy' },
          { text: 'Description', value: 'description' },
          { text: 'Amount', value: 'amount' },
          { text: 'Percentage', value: 'percent' },
          { text: 'Created At', value: 'createdAt' },
        ],
        discountsHeader: [
          { text: 'Status', value: 'status' },
          { text: 'Description', value: 'description' },
          { text: 'Percentage', value: 'percent' },
          { text: 'Amount', value: 'amount' },
          { text: 'BAP', value: 'notes' },
          { text: 'Created By', value: 'createdBy' },
          { text: 'Created At', value: 'createdAt' },
          { text: '', value: 'actions' },
        ],
        discountsHistoryHeader: [
          { text: '', value: 'current' },
          { text: 'Status', value: 'status' },
          { text: 'Created By', value: 'createdBy' },
          { text: 'Description', value: 'description' },
          { text: 'Amount', value: 'amount' },
          { text: 'Percentage', value: 'percent' },
          { text: 'Created At', value: 'createdAt' },
        ],
        paymentsHeader: [
          { text: 'Received By', value: 'receivedBy' },
          { text: 'Amount', value: 'amount' },
          { text: 'Created At', value: 'createdAt' },
          { text: '', value: 'actions' },
        ],
        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,
        data: [],
        singular: "HP",
        singularLower: "hp",
        plural: "HP",
        pluralLower: "hp",
        isEditable: false,
      }
    },
    async mounted(){
        try {

          this.getAllData()
          this.ppcLabels = this.getGlobalValue("VEC_HP_CONFIG").ppc.map(x=>x.label)
          console.log(this.ppcLabels)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed", "lookupUsername", "lookupPaymentType", "getGlobalValue", "paymentTypes"]),
      addPaymentDialogDisabledCriteria(){
        //returning true disables the button
        if(!this.addPaymentDialog.selectedType) return true
        if(!this.addPaymentDialog.tenderedAmount) return true
        if(parseFloat(this.addPaymentDialog.tenderedAmount)<0.01) return true
        // if(this.addPaymentDialog.tenderedAmount>Math.abs(this.data.balance)) return true
        if(this.lookupPaymentType(this.addPaymentDialog.selectedType)=="Cheque" && !this.addPaymentDialog.chequeNumber) return true
        if(this.lookupPaymentType(this.addPaymentDialog.selectedType)=="Credit Note" && !this.addPaymentDialog.creditNote) return true
        if(this.lookupPaymentType(this.addPaymentDialog.selectedType)=="Credit Note"){
          if(this.addPaymentDialog.tenderedAmount < 0) return true
        }
        return false
      },
      modifyDisabledCriteria(){ // returns true when an item can't be modified anymore
        return this.data.status !== 0
      }
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async sealHPA(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/hp/seal/${this.data.id}`)
          if(res.data.error) throw res.data.error

          this.getAllData()
        } catch (error) {
          console.log(error)
          if(error.msg){
            this.snack(error.msg, "error")
          }
        }
      },
      async closeHPA(creds){
        try {

          let obj = {
            username: creds.username,
            password: creds.password,
          }

          let res = await axios.post(`${this.getEndpoint}/api/hp/close/${this.data.id}`, obj)
          if(res.data.error) throw res.data.error

          this.getAllData()
        } catch (error) {
          console.log(error)
          if(error.msg){
            this.snack(error.msg, "error")
          }
        }
      },
      addEditPenaltyPercentValueChanged(){
        this.addEditPenaltyDialog.data.amount = null
        this.addEditPenaltyDialog.data.amount = (parseFloat(this.data.balance)*(parseFloat(this.addEditPenaltyDialog.data.percent)/100)).toFixed(2)
        this.$forceUpdate()
        console.log(this.addEditPenaltyDialog.data.amount)
      },
      addEditDiscountPercentValueChanged(){
        this.addDiscountDialog.data.amount = null
        this.addDiscountDialog.data.amount = (parseFloat(this.data.balance)*(parseFloat(this.addDiscountDialog.data.percent)/100)).toFixed(2)
        this.$forceUpdate()
        console.log(this.addDiscountDialog.data.amount)
      },
      async getAllData(){
        try {
          this.mainDataLoading = true
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error
          this.data = res.data.data
          
          console.log(this.data)

          for(let i in this.data){
            this.dataLoaders[i] = null
          }

          if(this.data.interestRate && this.data.interestRate.length > 0){
            this.dataArrays.interestRate = this.data.interestRate[this.data.interestRate.length-1].value
          }
          if(this.data.amount && this.data.amount.length > 0){
            this.dataArrays.amount = this.data.amount[this.data.amount.length-1].value
          }
          if(this.data.downpayment && this.data.downpayment.length > 0){
            this.dataArrays.downpayment = this.data.downpayment[this.data.downpayment.length-1].value
          }
          if(this.data.installment && this.data.installment.length > 0){
            this.dataArrays.installment = this.data.installment[this.data.installment.length-1].value
          }
          if(this.data.billingCycleDays && this.data.billingCycleDays.length > 0){
            this.dataArrays.billingCycleDays = this.data.billingCycleDays[this.data.billingCycleDays.length-1].value
          }
          if(this.data.term && this.data.term.length > 0){
            this.dataArrays.term = this.data.term[this.data.term.length-1].value
          }


        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.mainDataLoading = false
        }
      },
      async openTermHistoryDialog(type){
        try {
          this.termHistoryDialog.isLoading = true
          await this.getAllData()
          this.termHistoryDialog.type = type.toUpperCase()
          this.termHistoryDialog.isOpen = true
          
          if(!this.data[type] || !Array.isArray(this.data[type]) || this.data[type].length < 1) throw "Bad values"

          this.termHistoryDialog.data = this.data[type]
          this.termHistoryDialog.data.reverse()
        } catch (error) {
          console.log(error)
          this.snack(error)
        } finally {
          this.termHistoryDialog.isLoading = false
        }
      },
      closeTermHistoryDialog(){
        this.termHistoryDialog.isOpen = false
      },
      openAddNoteDialog(){
        this.addNoteDialog.isOpen = true
      },
      closeAddNoteDialog(){
        this.addNoteDialog.isOpen = false
        this.addNoteDialog.data = ""
      },
      openViewPaymentDialog(item){
        this.viewPaymentDialog.paymentToDisplay = item
        this.viewPaymentDialog.isOpen = true
      },
      closeViewPaymentDialog(){
        this.viewPaymentDialog.isOpen = false
        this.viewPaymentDialog.paymentToDisplay = {}
      },
      openAddPaymentDialog(){
        this.addPaymentDialog.isOpen = true
        this.addPaymentDialog.data = {
          tenderedAmount: 0,
          selectedType: "",
          notes: ""
        }
      },
      closeAddPaymentDialog(){
        this.addPaymentDialog = {
          isOpen: false,
          isLoading: false,
          data: {}
        }
      },
      async addPayment(){
        try {
          this.addPaymentDialog.isLoading = true
          let obj = {
            orderId: this.data.orderId,
            tenderedAmount: this.addPaymentDialog.tenderedAmount,
            paymentType: this.addPaymentDialog.selectedType,
            metadata: {
              chequeNumber: this.addPaymentDialog.chequeNumber,
              creditNotes: this.addPaymentDialog.creditNote,
              pitBalance: this.data.balance
            },
            notes: this.addPaymentDialog.notes,
          }

          let res = await axios.post(`${this.getEndpoint}/api/hp/payment/${this.data.orderId}`, obj)
          if(res.data.error) throw res.data.error

          this.snack("Payment Added")
          
          this.closeAddPaymentDialog()
          this.snack("Payment Successfully Added.")
          
          await this.getAllData()

        } catch (error) {
          console.log(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.addPaymentDialog.isLoading = false
        }
      },
      openAddEditSponsorsDialog(addMode,item){
        this.addEditSponsorsDialog.isOpen = true
        this.addEditSponsorsDialog.addMode = addMode
        if(!addMode){
          this.addEditSponsorsDialog.data = item
        }
      },
      closeAddEditSponsorsDialog(){
        this.addEditSponsorsDialog = {
          isOpen: false,
          isLoading: false,
          addMode: true,
          data: {
            name: null,
            phone: null,
            email: null,
            relationship: null,
          }
        }
      },
      async addNote(){
        try {
          this.addNoteDialog.isLoading = true;

          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/note/${this.$route.params.id}`, {note: this.addNoteDialog.data})
          await this.getAllData()
          if(res.data.error) throw res.data.error

          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.addNoteDialog.isLoading = false;
          this.closeAddNoteDialog()
        }
      },
      async addSponsors(){
        try {
          this.dataLoaders.sponsors = true;

          if(!this.data.sponsors) this.data.sponsors = []
          
          this.data.sponsors.push(this.addEditSponsorsDialog.data) 
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/sponsors/${this.$route.params.id}`, {value: this.data.sponsors})
          await this.getAllData()
          if(res.data.error) throw res.data.error

          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.sponsors = false;
          this.closeAddEditSponsorsDialog()
        }
      },
      async updateSponsors(index){
        try {
          this.dataLoaders.sponsors = true;
          this.data.sponsors[index] = this.addEditSponsorsDialog.data
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/sponsors/${this.$route.params.id}`, {value: this.data.sponsors})
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} info Updated 🎉`, "success");
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.sponsors = false;
          this.closeAddEditSponsorsDialog()
        }
      },
      async deleteSponsors(index){
        try {
          this.dataLoaders.sponsors = true;
          this.data.sponsors.splice(index,1)
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/sponsors/${this.$route.params.id}`, {value: this.data.sponsors})
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} info Updated 🎉`, "success");
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.sponsors = false;
          this.closeAddEditSponsorsDialog()
        }
      },
      openAddEditEmployersDialog(addMode,item){
        this.addEditEmployersDialog.isOpen = true
        this.addEditEmployersDialog.addMode = addMode
        if(!addMode){
          this.addEditEmployersDialog.data = item
        }
      },
      closeAddEditEmployersDialog(){
        this.addEditEmployersDialog = {
          isOpen: false,
          isLoading: false,
          addMode: true,
          data: {
            occupation: null,
            salary: null,
            name: null,
            phone: null,
            email: null,
            address: null,
            contactName: null,
            contactPhone: null,
            contactEmail: null,
            contactOccupation: null,
            employmentStarted: null,
            employmentEnded: null,
          }
        }
      },
      async addEmployers(){
        try {
          this.dataLoaders.employers = true;

          if(!this.data.employers) this.data.employers = []
          
          this.data.employers.push(this.addEditEmployersDialog.data) 
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/employers/${this.$route.params.id}`, {value: this.data.employers})
          await this.getAllData()
          if(res.data.error) throw res.data.error

          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.employers = false;
          this.closeAddEditEmployersDialog()
        }
      },
      async updateEmployers(index){
        try {
          this.dataLoaders.employers = true;
          this.data.employers[index] = this.addEditEmployersDialog.data
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/employers/${this.$route.params.id}`, {value: this.data.employers})
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} info Updated 🎉`, "success");
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.employers = false;
          this.closeAddEditEmployersDialog()
        }
      },
      async deleteEmployers(index){
        try {
          this.dataLoaders.employers = true;
          this.data.employers.splice(index,1)
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/employers/${this.$route.params.id}`, {value: this.data.employers})
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} info Updated 🎉`, "success");
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.employers = false;
          this.closeAddEditEmployersDialog()
        }
      },
      async updateAmount(){
        try {
          this.dataLoaders.amount = true;

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/amount/${this.$route.params.id}`, {value: this.dataArrays.amount})

          if(res.data.error) throw res.data.error
          this.dataArrays.amount = res.data.data.amount[res.data.data.amount.length-1].value
          this.$forceUpdate()

          this.snack(`${this.singular} info Updated 🎉`, "success");
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.amount = false;
        }
      },
      openPenaltyHistoryDialog(index,data){
        this.penaltyHistoryDialog.isOpen = true
        data.push(this.data.penalties[index])
        this.penaltyHistoryDialog.data = data.reverse()
        this.penaltyHistoryDialog.data[0].currentValue = true
      },
      closePenaltyHistoryDialog(){
        this.penaltyHistoryDialog = {
          isOpen: false,
          isLoading: false,
          data: []
        }
      },  
      openAddEditPenaltyDialog(addMode,index){
        this.addEditPenaltyDialog.isOpen = true
        this.addEditPenaltyDialog.addMode = addMode
        if(!addMode){
          this.addEditPenaltyDialog.index = index
          this.addEditPenaltyDialog.data = this.data.penalties[index]
        }else {
          this.addEditPenaltyDialog.data = {}
        }
      },
      closeAddEditPenaltyDialog(){
        this.addEditPenaltyDialog = {
          isOpen: false,
          isLoading: false,
          addMode: true,
          index: null,
          data: {
            description: null,
            isPenalty: null,
            amount: null,
            percent: null,
          }
        }
      },
      async addPenalty(){
        try {
          this.dataLoaders.penalty = true;
  
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/penalty/${this.$route.params.id}`, {value: this.addEditPenaltyDialog.data})
          await this.getAllData()

          if(res.data.error) throw res.data.error
          
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.penalty = false;
          this.closeAddEditPenaltyDialog()
        }
      },
      async updatePenalty(index){
        try {
          this.dataLoaders.Penalty = true;
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/penalties/${this.$route.params.id}`, {index: index, value: this.addEditPenaltyDialog.data})
          if(res.data.error) throw res.data.error
          
          await this.getAllData()

          this.snack(`${this.singular} info Updated 🎉`, "success");
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.Penalty = false;
          this.closeAddEditPenaltyDialog()
        }
      },
      async deletePenalty(index){
        try {
          this.dataLoaders.Penalty = true;
          let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/penalty/${this.$route.params.id}/${index}`)
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} info Updated 🎉`, "success");
          this.isEditable = false;
          await this.getAllData()
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.Penalty = false;
          this.closeAddEditPenaltyDialog()
        }
      },
      openAddDiscountDialog(addMode,index){
        this.addDiscountDialog.isOpen = true
        this.addDiscountDialog.addMode = addMode
        if(!addMode){
          this.addDiscountDialog.index = index
          this.addDiscountDialog.data = this.data.discounts[index]
        }else {
          this.addDiscountDialog.data = {}
        }
      },
      closeAddDiscountDialog(){
        this.addDiscountDialog = {
          isOpen: false,
          isLoading: false,
          addMode: true,
          index: null,
          data: {
            description: null,
            amount: null,
            percent: null,
          }
        }
      },
      closeDiscountHistoryDialog(){
        this.discountHistoryDialog = {
          isOpen: false,
          isLoading: false,
          data: []
        }
      },    
      async addDiscount(){
        try {
          this.dataLoaders.discount = true;
  
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/discount/${this.$route.params.id}`, {value: this.addDiscountDialog.data})
          await this.getAllData()

          if(res.data.error) throw res.data.error
          
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.discount = false;
          this.closeAddDiscountDialog()
        }
      },
      async updateDiscount(index){
        try {
          this.dataLoaders.discount = true;
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/discount/${this.$route.params.id}`, {index: index, value: this.addDiscountDialog.data})
          if(res.data.error) throw res.data.error
          
          await this.getAllData()

          this.snack(`${this.singular} info Updated 🎉`, "success");
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.discount = false;
          this.closeAddDiscountDialog()
        }
      },
      async deleteDiscount(index){
        try {
          this.dataLoaders.discount = true;
          let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/discount/${this.$route.params.id}/${index}`)
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} info Updated 🎉`, "success");
          this.isEditable = false;
          await this.getAllData()
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.discount = false;
          this.closeAddDiscountDialog()
        }
      },
      async updateDownpayment(){
        try {

          this.dataLoaders.downpayment = true;

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/downpayment/${this.$route.params.id}`, {value: this.dataArrays.downpayment})

          if(res.data.error) throw res.data.error
          this.dataArrays.downpayment = res.data.data.downpayment[res.data.data.downpayment.length-1].value
          this.$forceUpdate()
          
          this.snack(`${this.singular} info Updated 🎉`, "success");

        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.downpayment = false;
        }
      },
      async updateInterestRate(){
        try {
          this.dataLoaders.interestRate = true;

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/interestRate/${this.$route.params.id}`, {value: this.dataArrays.interestRate})

          if(res.data.error) throw res.data.error
          this.dataArrays.interestRate = res.data.data.interestRate[res.data.data.interestRate.length-1].value
          this.$forceUpdate()
          
          this.snack(`${this.singular} info Updated 🎉`, "success");
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.interestRate = false;
        }
      },
      async updateInstallment(){
        try {
          this.dataLoaders.installment = true;

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/installment/${this.$route.params.id}`, {value: this.dataArrays.installment})

          if(res.data.error) throw res.data.error
          this.dataArrays.installment = res.data.data.installment[res.data.data.installment.length-1].value
          this.$forceUpdate()
          
          this.snack(`${this.singular} info Updated 🎉`, "success");
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.installment = false;
        }
      },
      async updateBillingCycleDays(){
        try {
          this.dataLoaders.billingCycleDays = true;

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/billingCycleDays/${this.$route.params.id}`, {value: this.dataArrays.billingCycleDays})

          if(res.data.error) throw res.data.error
          this.dataArrays.billingCycleDays = res.data.data.billingCycleDays[res.data.data.billingCycleDays.length-1].value
          this.$forceUpdate()
          
          this.snack(`${this.singular} info Updated 🎉`, "success");
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.billingCycleDays = false;
        }
      },
      async updateTerm(){
        try {
          this.dataLoaders.term = true;

          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/term/${this.$route.params.id}`, {value: this.dataArrays.term})
          if(res.data.error) throw res.data.error
          this.dataArrays.term = res.data.data.term[res.data.data.term.length-1].value
          this.$forceUpdate()
          
          this.snack(`${this.singular} info Updated 🎉`, "success");
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.dataLoaders.term = false;
        }
      },
      async update(){
        try {
          this.loader = true;
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`, this.data)
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} info Updated 🎉`, "success");
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      }
    }
  }
</script>
